html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.quill {
  border-radius: 10px;
  width: 100%;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.MuiAutocomplete-tag {
  border-radius: 10px !important;
}

.MuiInputBase-root {
  border-radius: 10px !important;

  // .MuiTablePagination-select {
  //   padding-top: 16px;
  // }

  // .MuiTablePagination-selectIcon {
  //   padding-top: 5px !important;
  // }

  // .MuiOutlinedInput-input {
  //   padding: 9.5px 14px;
  // }
}

.grecaptcha-badge {
  visibility: hidden;
}

// .MuiMenu-paper:hover {
//   color: #000 !important;
// }

// .MuiMenuItem-root {
//   padding: 5px !important;
// }

.error-textfield {
  width: 100%;
  border-radius: 10px !important;
}

.MuiFormHelperText-root {
  color: red !important;
}
